<template>
  <UiPopup
    :model-value="modelValue"
    title="Set up Authenticator"
    :primary-button-text="step === 1 ? 'Next' : 'Done'"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading"
    @update:model-value="closePopup"
    @confirm="step === 1 ? (step = 2) : enableTwoFA()"
  >
    <div v-if="step === 1" class="px-2">
      <ul class="ml-4 list-disc text-left">
        <li><span class="relative leading-6">Get the Google Authenticator App from the App store.</span></li>
        <li><span class="relative leading-6">In the App select Set up Account.</span></li>
        <li><span class="relative leading-6">Choose Scan barcode.</span></li>
      </ul>
      <div class="flex justify-center pt-4">
        <UiLoader v-if="loadingQr"></UiLoader>
        <img v-else :src="qrCodeData.img_url" alt="QR code" />
      </div>
    </div>
    <form v-else-if="step === 2" class="px-2" @submit.prevent="enableTwoFA">
      <p class="text-center">Enter the verification code generated by your Authenticator app.</p>
      <UiInputTextField
        v-model="data.code"
        name="Code"
        placeholder="Enter the 6-digit code"
        label="Code"
        type="string"
        required
        :error="v$.code.$errors[0] ? v$.code.$errors[0].$message.toString() : ''"
        class="pt-4"
      />
    </form>
  </UiPopup>
</template>
<script setup lang="ts">
import { required, helpers, minLength, maxLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useAuthStore } from '~/store/auth'
import type { QRCode } from '@/types'
import { useUiStore } from '~/store/ui'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const uiStore = useUiStore()
const authStore = useAuthStore()

const step = ref<Number>(authStore.user.two_factor_secret ? 2 : 1)
const data = ref({
  code: '',
})
const qrCodeData = ref<QRCode>({ secret: '', img_url: '' })
const loading = ref(false)
const loadingQr = ref(false)

const rules = computed(() => ({
  code: {
    required: helpers.withMessage('Code is required', required),
    minLength: helpers.withMessage('Code should have 6 characters ', minLength(6)),
    maxLength: helpers.withMessage('Code should have 6 characters ', maxLength(6)),
  },
}))
const v$ = useVuelidate(rules, data, { $stopPropagation: true })

onNuxtReady(async () => {
  if (!authStore.user.two_factor_secret) {
    loadingQr.value = true
    qrCodeData.value = await useQRCode()
    loadingQr.value = false
  }
})

const closePopup = () => {
  emits('update:modelValue', false)
}

const enableTwoFA = async () => {
  if (!(await v$.value.$validate())) return

  loading.value = true
  try {
    if (authStore.user.two_factor_secret) {
      await useEnabledTwoFA(authStore.user.two_factor_secret, data.value.code, 0)
      uiStore.showSnackBanner('Two factor authentication disabled!')
    } else {
      await useEnabledTwoFA(qrCodeData.value.secret, data.value.code, 1)
      uiStore.showSnackBanner('Two factor authentication enabled!')
    }
    closePopup()
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>
<style scoped lang="scss"></style>
